import { Slide, ThemeOptions } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { FC } from "react";
import { IColors, IFontFamily, ISizeUnits } from "./types";

declare module "@mui/material/styles" {
  interface CustomTheme {
    colors?: IColors;
    fontSizes: ISizeUnits;
    fontFamily?: IFontFamily;
  }

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

const theme = (themeOptions?: ThemeOptions) => createTheme(themeOptions);

interface ThemesProps {
  children?: React.ReactNode;
  themeOptions?: ThemeOptions;
}

const Themes: FC<ThemesProps> = (props) => {
  const themeOptions: ThemeOptions = {
    palette: {
      primary: { main: "#000000" },
      secondary: { main: "#FFFFFF" },
    },
    colors: {
      primary: {
        default: "Green",
      },
      secondary: {
        default: "#2C2C83",
      },
      ternary: {
        default: "#8890A7"
      },
      quaternary: {
        default: '#F3F6FC'
      },
      colorFive: {
        default: '#F8F9FB'
      },
      colorSix: {
        default: '#EAEEF6'
      },
      colorSeven: {
        default: 'green'
      },
      success: {
        default: "#008000",
      },
      danger: {
        default: "#FF0000",
      },
      warning: {
        default: "#FFA500",
      },
      info: {
        default: "00008B",
      },
      primaryButtons: {
        default: "#EB568F",
      },
      greyText: {
        default: 'rgba(14, 28, 63, 0.73)',
      },
      darkGreyText: {
        default: 'rgba(14, 28, 63, 0.88)',
      },
      lightGreyText: {
        default: 'rgba(14, 28, 63, 0.55)',
      },
    },

    fontSizes: {
      h1: "34px",
      h2: "22px",
      h3: "20px",
      h4: "16px",
      xl: "18px",
      large: "16px",
      medium: "14px",
      small: "12px",
    },

    fontFamily: {
      primary: "",
      secondary: "",
    },
  };
  return (
    <ThemeProvider theme={theme(themeOptions)}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        TransitionComponent={Slide as any}
        autoHideDuration={3000}
      >
        {props.children}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Themes;
