import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, IUser } from "../interfaces";

interface ProductService {
  createBrand: (data: {}) => Promise<IAPIResponse>;
  updateBrand: (data: {}, id: string) => Promise<IAPIResponse>;
  getAllBrandsList: () => Promise<IAPIResponse<any[]>>;
  listAllBrands: (page: number, limit: number) => Promise<IAPIResponse<any[]>>;
  deleteBrand: (id: string) => Promise<IAPIResponse>;
  getSingle: (id: string) => Promise<IAPIResponse<any | null>>;
  addThumbail: (data: {}) => Promise<IAPIResponse<any | null>>;
  removeCategoryAPi: (
    brandId: string,
    categoryId: string
  ) => Promise<IAPIResponse<any | null>>;
}

const categoryService = (httpClient: AxiosInstance): ProductService => {
  return {
    createBrand: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/brand/add",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Brand creation failed" };
      }
    },
    getAllBrandsList: async (): Promise<IAPIResponse<any[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any[]>> = await httpClient.get(
          "/get/all/brands/admin"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch brands" };
      }
    },
    listAllBrands: async (page, limit): Promise<IAPIResponse<any[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any[]>> = await httpClient.get(
          `/list/all/brands/admin?page=${page}&limit=${limit}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch brands" };
      }
    },
    deleteBrand: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/delete/brand/",
          {
            brandId: id,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User deletion failed" };
      }
    },
    getSingle: async (id): Promise<IAPIResponse<any | null>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser | null>> =
          await httpClient.post("/get/single/brand/", { brandId: id });
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch brand" };
      }
    },
    updateBrand: async (data, id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          `/update/brand/`,
          data
        );
        return res.data;
      } catch (error) {
        console.log(error);
        return { status: false, message: "Brand update failed" };
      }
    },
    addThumbail: async (data) => {
      try {
        const res = await httpClient.post("/add/brand/image/", data);
        return res.data;
      } catch (err) {
        console.log("err", err);
        return { status: false, message: "File upload failed" };
      }
    },
    removeCategoryAPi: async (brandId, categoryId) => {
      try {
        const res = await httpClient.post("/remove/category/brand/", {
          brandId,
          categoryId,
        });
        return res.data;
      } catch (err) {
        console.log("err", err);
        return { status: false, message: "Failed to remove category" };
      }
    },
  };
};

export default categoryService;
