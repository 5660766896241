import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
} from "@mui/material";
import { BiHistory, BiListUl, BiShareAlt, BiTrash } from "react-icons/bi";
import IconButton from "../common/custom-buttons/IconButton";
import { useLocation, useParams } from "react-router-dom";
import { userService } from "../../service";
import { breadCrumbState } from "../../utils/storage-utils";
import { IBreadCrumbState } from "../../interfaces";
import CustomButton from "../common/custom-buttons/CustomButton";
import { styled, alpha, useTheme } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
// import CloseIcon from "@mui/icons-material/Close";
interface IProps {
  title: string;
  onSearch?: (search: string) => void;
  buttonText?: string;
  buttonIcon?: ReactNode;
  onButtonClick?: VoidFunction;
  onDelete?: VoidFunction;
  onEdit?: VoidFunction;
  onShare?: VoidFunction;
  isSearch?: Boolean;
  onRefresh?: VoidFunction;
  onDeleteAll?: any;
}
export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginRight: theme.spacing(1.4),
    width: "491px",
  },
  height: 56,
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "43px",
  lineHeight: "43px",
  position: "absolute",
  //pointerEvents: "pointer",
  right: "-18px",
  top: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#8A8A8A",
  height: 56,
  "& .MuiInputBase-input": {
    padding: theme.spacing(0, 0, 0, 0),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    paddingLeft: "18px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 443,
    },
    background: "#F3F4F8",
    borderRadius: 8,
  },
}));

const SubTopBar: FC<IProps> = (props) => {
  const {
    title,
    buttonText,
    buttonIcon,
    onButtonClick,
    onSearch,
    isSearch = true,
    onShare,
    onRefresh,
    onDeleteAll,
  } = props;
  const theme = useTheme();
  const location = useLocation();
  const { id, superAdminId, adminId, userId, recordId } = useParams();
  const [pageTitle, setPageTitle] = useState<IBreadCrumbState[]>([
    { id: "", name: title },
  ]);

  const getBreadCrumbs = useCallback(async (id: string) => {
    let name = await getUser(id);

    const breadCrumbDatas = breadCrumbState.get();
    let index = 0;
    let flag = false;
    for (const data of breadCrumbDatas) {
      if (data.id !== id) {
        continue;
      }

      flag = true;
      index = breadCrumbDatas.indexOf(data);
    }

    if (flag) {
      const temp = [...breadCrumbDatas.slice(0, index + 1)];
      breadCrumbState.set(temp);
      setPageTitle([{ id: "", name: "files", link: "/files" }, ...temp]);
      return;
    }

    const temp = [...breadCrumbDatas, { id, name, link: location.pathname }];
    breadCrumbState.set(temp);
    setPageTitle([{ id: "", name: "files", link: "/files" }, ...temp]);
  }, [location]);

  const getValidId = useCallback(() => {
    if (id) return id;
    if (superAdminId) return superAdminId;
    if (adminId) return adminId;
    if (userId) return userId;
    if (recordId) return recordId;
    breadCrumbState.clear();
    setPageTitle([{ id: "", name: "files", link: "/files" }]);
    return null;
  }, [adminId, id, recordId, superAdminId, userId]);

  useEffect(() => {
    const validId = getValidId();
    console.log(location.pathname);
    if (
      title !== "Files" ||
      location.pathname.split("/")[1].toLowerCase() !== title.toLowerCase() ||
      !validId
    ) {
      setPageTitle([{ id: "", name: title }]);
      return;
    }

    getBreadCrumbs(validId);
  }, [location.pathname, title, id, superAdminId, adminId, userId, recordId, getBreadCrumbs, getValidId]);



  const getUser = async (id: string) => {
    const { status, data } = await userService.getSingle(id);
    if (!status || !data) return "";
    return data.name;
  };



  // State for the search input value
  // const [searchValue, setSearchValue] = useState("");

  // Function to handle changes in the search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setSearchValue(e.target.value);
    // Call the onSearch function passed through props
    // Send the search query to the parent component
    onSearch && onSearch(e.target.value);
  };

  return (
    <Box
      height="60px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingBottom="35px"
      paddingRight="28px"
    >
      <Box display="inherit" alignItems="inherit" height="inherit">
        <Box>
          <Breadcrumbs aria-label="breadcrumb">
            {pageTitle.map((d, index) =>
              pageTitle.length !== index + 1 ? (
                <Link
                  underline="hover"
                  sx={{
                    textTransform: "capitalize",
                    width: "fit-content",
                    fontSize: 22,
                  }}
                  href={d.link}
                  key={index}
                >
                  {d.name}
                </Link>
              ) : (
                <Typography
                  textTransform={"capitalize"}
                  width={"fit-content"}
                  fontSize={22}
                  key={index}
                >
                  {d.name}
                </Typography>
              )
            )}
          </Breadcrumbs>
        </Box>
      </Box>
      {isSearch && <Search sx={{ pl: 0 }}>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search", onChange: handleSearchChange }}
          sx={{ "& .MuiInputBase-input": { height: "43px" } }}
        />
        <SearchIconWrapper>
          <IconButton sx={{ height: 43, minWidth: 43 }}>
            <SearchIcon
              sx={{ color: "green", "&:hover": { color: "white" } }}
            />
          </IconButton>
        </SearchIconWrapper>
      </Search>}
      <Box display="flex" alignItems="center" gap={3}>
        {buttonText && (
          <Box display="inherit" alignItems="inherit">
            <CustomButton
              onClick={() => onButtonClick && onButtonClick()}
              sx={{
                backgroundColor: theme.colors?.colorSeven.default,
                pl: "22px",
                pr: "22px",
                borderRadius: "10px",
              }}
              startIcon={buttonIcon}
            >
              {buttonText}
            </CustomButton>
          </Box>
        )}
        <Box display="flex" gap={2.2}>
          {/* {onDelete && (
            <IconButton onClick={() => onDelete()}>
              <BiTrash fontSize={20} />
            </IconButton>
          )} */}
          {onShare && (
            <IconButton onClick={() => onShare()}>
              <BiShareAlt fontSize={20} />
            </IconButton>
          )}
          {onRefresh && ( //RESTORE
            <IconButton onClick={() => onRefresh()}>
              <BiHistory fontSize={20} />
            </IconButton>
          )}
          {false && (
            <IconButton>
              <BiListUl fontSize={20} />
            </IconButton>
          )}
          {onDeleteAll && (
            <IconButton onClick={() => onDeleteAll()}>
              <BiTrash fontSize={20} />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SubTopBar;
